.mainbar{
  min-height: 59px;
  height: 59px;
  .navbar-minimize .btn,
  li#projectSelect a.dropdown-toggle.btn  {
    margin: 10px 3px 0;
    height: 38px;
    width: 38px;
  }
  .nav.navbar-nav{
    li{
      a{
        padding: 0;
        margin: 0;
        &.dropdown-toggle{
          cursor: pointer;
        }
      }
    }
    .dropdown-menu{
      li{
        a{
          padding: 10px 15px;
        }
      }
    }
  }
  // sidebar open
  i.fas.fa-ellipsis-v{
    display: block;
  }
  i.fas.fa-ellipsis-h{
    display: none;
  }
  // sidebar closed
  .sidebar-mini &{
    i.fas.fa-ellipsis-v{
      display: none;
    }
    i.fas.fa-ellipsis-h{
      display: block;
    }
  }
  // Cog
  .fa-cog, .fa-user{
    color: #65605a;
  }
  #projectHomeLink{
    font-size: 32px;
    margin: 5px 0 0;
  }
  #appAdmin{
    margin: 10px 0 0;
  }
  #appUser{
    margin: 5px 0 0 20px;
    font-size: 32px;
    font-weight: lighter;
  }
}
